import ApiService from '@/services/ApiService'

const DocumentService = {
  documentTypes(limit_data = 0) {
    if (limit_data) {
      return ApiService.get(`document-types?limit_data=${limit_data}`)
    }
    return ApiService.get('document-types')
  },
  getTags() {
    return ApiService.get('tags')
  },
  getAttributes() {
    return ApiService.get('attributes')
  },
  getServiceUsers(data) {
    return ApiService.get(`service-users/search?s=${data.keyword}&page=${data.page}&limit=${data.limit}`)
  },
  createTag(name) {
    return ApiService.post('tags', {name})
  },
  deleteTag(id) {
    return ApiService.delete(`tags/${id}`)
  },
  list(id, page, limit) {
    if(page && limit) {
      return ApiService.get(`service-users/${id}?page=${page}&limit=${limit}`)
    }
    return ApiService.get(`service-users/${id}`)
  },
  create(data) {
    return ApiService.post('documents', data)
  },
  update(id, data) {
    return ApiService.post(`documents/${id}`, data)
  },
  detail(id, page, limit) {
    if(page && limit) {
      return ApiService.get(`documents/${id}?page=${page}&limit=${limit}`)
    }
    return ApiService.get(`documents/${id}`)
  },
  delete(id) {
    return ApiService.delete(`documents/${id}`)
  },
  fileVersions(id) {
    return ApiService.get(`files/${id}/versions`)
  },
  filePreview(id) {
    return ApiService.get(`file-histories/${id}/preview`)
  },
  downloadFile(id) {
    return ApiService.get(`files/${id}/download`, {responseType: 'blob'})
  },
  downloadFileHistory(id) {
    return ApiService.get(`file-histories/${id}/download`, {responseType: 'blob'})
  },
  deleteFile(id) {
    return ApiService.delete(`files/${id}`)
  },
  mailTemplate() {
    return ApiService.get('mail-templates')
  },
  settingAlert(id, data) {
    return ApiService.post(`documents/${id}/setting-alert-mail`, data)
  },
  settingRole(id, role_setting) {
    return ApiService.post(`documents/${id}/setting-document-access`, {role_setting})
  },
  settingFileAccess(id, role_setting) {
    return ApiService.post(`service-users/${id}/setting-file-set-access`, {role_setting})
  },
}

export default DocumentService
