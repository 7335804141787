const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const upperCamelCaseToSnakeCase = (value) => {
    return value
        .replace(/^([A-Z])/, ($1) => $1.toLowerCase())
        .replace(/([A-Z])/g, ($1) => `-${$1.toLowerCase()}`);
};

const milliseconds = (h = 0, m = 0, s = 0) => (h * 60 * 60 + m * 60 + s) * 1000;

const formatNumber = (number, places, thousand, decimal) => {
    number = number || 0
    places = !isNaN(places = Math.abs(places)) ? places : 2
    thousand = thousand || ','
    decimal = decimal || '.'
    var negative = number < 0 ? '-' : ''
    var i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + ''
    let j = i.length
    j = j > 3 ? (j % 3) : 0

    return negative + (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : '')
}
const katakana = (value) => {
    const jaRegex = /[ァ-ン]/;
    return !value || jaRegex.test(value);
};
const kanji = (value) => {
    const jaRegex = /[一-龯]/;
    return !value || jaRegex.test(value);
};
const extension = (extensions) =>
    (value) => {
        if (value === undefined || value === null || value.length === 0) {
            return true
        }

        const ext = value.name.split('.').pop()
        return extensions.indexOf(ext.toLowerCase()) !== -1
    }
const fileSizeValidation = (value) => {
    if (!value) {
        return true
    }
    return (value.size < 1024 * 1024)
}
const checkDevice = (navigator) => {
    if (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)) {
        return true
    } else if (/Android|webOS|iPhone|iPad|iPad Simulator|iPhone Simulator|iPod Simulator|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}

const halfWidth = (value) => !/[Ａ-Ｚａ-ｚ０-９]/.test(value);
const checkAdCode = value => /^[a-zA-Z0-9!@#$%^& *)(+=._-]*$/.test(value) || value.length === 0

const XSSCheck = (value) => !/['"<>]/.test(value)
export {
    capitalize,
    upperCamelCaseToSnakeCase,
    milliseconds,
    formatNumber,
    katakana,
    kanji,
    extension,
    fileSizeValidation,
    XSSCheck,
    halfWidth,
    checkAdCode,
    checkDevice
};
