export default [
  {
    path: 'user-service',
    name: 'UserService',
    component: () => import('@/views/user-service/UserServiceList'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2]
    },
  },
  {
    path: 'user-service/create',
    name: 'UserServiceCreate',
    component: () => import('@/views/user-service/UserServiceCreate'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2]
    },
  },
  {
    path: 'user-service/update/:id',
    name: 'UserServiceUpdate',
    component: () => import('@/views/user-service/UserServiceUpdate'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2]
    },
  },
]
