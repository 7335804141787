<template>
  <div>
    <nav class="pagination is-small is-centered" role="navigation" aria-label="pagination" v-if="pages">
      <ul class="pagination-list">
        <li>
          <a class="pagination-link is-borderless" @click="changePage(current - 1)">
            <img src="@/assets/svgs/icon_drop.svg" width="15px" style="transform: rotate(90deg)"/>
          </a>
        </li>
        <li>
          <a class="pagination-link no-bg-paginate"
             :class="{' is-current': current === 1 }"
             @click="changePage(1)">1</a>
        </li>
        <li v-if="pages >= 4 && current >= 4">
          <span>...</span>
        </li>
        <li v-if="current - 1 > 1">
          <a class="pagination-link no-bg-paginate"
             @click="changePage(current - 1)">{{ current - 1 }}</a>
        </li>
        <li v-if="current !== 1 && current !== pages">
          <a class="pagination-link no-bg-paginate is-current"
             @click="changePage(current)">{{ current }}</a>
        </li>
        <li v-if="current + 1 < pages">
          <a class="pagination-link no-bg-paginate"
             @click="changePage(current + 1)">{{ current + 1 }}</a>
        </li>
        <li v-if="pages >= 4 && current <= pages - 3">
          <span>...</span>
        </li>
        <li v-if="pages > 1">
          <a class="pagination-link no-bg-paginate"
             :class="{' is-current': current === pages }"
             @click="changePage(pages)">{{ pages }}</a>
        </li>
        <li>
          <a class="pagination-link is-borderless" @click="changePage(current + 1)">
            <img src="@/assets/svgs/icon_drop.svg" width="15px" style="transform: rotate(-90deg)"/>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10
    },
  },
  data() {
    return {}
  },
  computed: {
    pages() {
      return Math.ceil(this.total / this.perPage)
    },
  },
  methods: {
    changePage(page) {
      if(page > 0 && page <= this.pages && page !== this.current) {
        this.$emit('change', page)
      }
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
  li {
    .pagination-link {
      border-radius: 15px;
      color: #ffffff ;
      background-color: $gray_paginate;
      &.is-current {
        background-color: $blue_paginate  !important;
        color: #ffffff;
      }
      &.is-borderless {
        color: #808080;
        &:hover {
          color: #c585b2 ;
        }
      }
    }
    &:nth-last-child(1) {
      a {
        background-color: white;
      }
    }
    &:nth-child(1) {
      a {
        background-color: white;
      }
    }
  }
</style>