import i18n from "@/lang/i18n";
export default [
  {
    path: 'mail-templates',
    name: 'MailTemplate',
    component: () => import('@/views/mail-templates/MailTemplateList'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2],
      title: i18n.t('router.mail_template')
    },
  },
  {
    path: 'mail-templates/create',
    name: 'MailTemplateCreate',
    component: () => import('@/views/mail-templates/MailTemplateCreate'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2]
    },
  },
  {
    path: 'mail-templates/:id',
    name: 'MailTemplateUpdate',
    component: () => import('@/views/mail-templates/MailTemplateUpdate'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2]
    },
  },
]
