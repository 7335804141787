import i18n from "@/lang/i18n";
export default [
  {
    path: 'users',
    name: 'Users',
    component: () => import('@/views/users/UserList'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2],
      title: i18n.t('router.user_list')
    },
  },
  {
    path: 'users/create',
    name: 'Users.Create',
    component: () => import('@/views/users/UserCreate'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2],
      title: i18n.t('router.user_create')
    },
  },
  {
    path: 'users/update/:id',
    name: 'Users.Update',
    component: () => import('@/views/users/UserUpdate'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2],
      title: i18n.t('router.user_update')
    },
  }
]
