import ApiService from '@/services/ApiService';

const UserService = {
  list(page, limit, employee_id, name, email, branches, divisions, offices, roles, positions) {
    return ApiService.get(`users?page=${page}&limit=${limit}&employee_id=${employee_id}&name=${name}&email=${email}&branches=${branches}&divisions=${divisions}&offices=${offices}&roles=${roles}&positions=${positions}`)
  },

  listAll() {
    return ApiService.get('users')
  },

  listUsers(limit_data = 0) {
    if (limit_data) {
      return ApiService.get(`list-users?limit_data=${limit_data}`)
    }
    return ApiService.get('list-users')
  },


  create(data) {
    return ApiService.post('users', data)
  },

  update(id, data) {
    return ApiService.update(`users/${id}`, data)
  },

  delete(id) {
    return ApiService.delete(`users/${id}`)
  },

  detail(id) {
    return ApiService.get(`users/${id}`)
  },

  changeRole(data) {
    return ApiService.post('users/setting-role', data)
  },

  changePosition(data) {
    return ApiService.post('users/change-position', data)
  },

  changeOffice(data) {
    return ApiService.update('users/change-office', data)
  },

  changePassword(data) {
    return ApiService.post('users/change-password', data)
  },
  changeStatus(id, status) {
    return ApiService.post(`users/${id}/change-status`, {status})
  },
  deleteAll(data) {
     return ApiService.post(`users/delete`, data)
  },
  restore(data) {
    return ApiService.post(`users/restore`, data)
  },
}

export default UserService