import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/stores'
// import {ACTION_SET_ERROR} from '@/stores/common/actions'
import {FORBIDDEN} from '@/helpers/message'
import i18n from "@/lang/i18n";
// import Home from "../views/Home";
import auth from "./auth";
import search from './search';
import user from './user';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import importMailAddress from './import-mail-address'
import folder from './folder'
import profile from './profile';
import userService from './user-service'
import documentRouter from './document'
import mailTemplate from './mail-template'
import pageSetting from'./page-setting'
import {ACTION_CLEAR_SEARCH_PARAMS} from "@/stores/common/actions";

Vue.use(VueToast, {
  position: 'top-right'
});

Vue.use(VueRouter)

const routes = [
  ...auth,
  {
    path: "*",
    component: () => import('@/components/common/page-not-found'),
  },
  {
    path: '',
    component: () => import('@/components/layouts/layout-app'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'home',
        meta: {
          title: i18n.t('login.title'),
          require_auth: true
        },
        component: () => import('@/views/search/SearchDocuments'),
      },
      // route modules
      ...search,
      ...user,
      ...importMailAddress,
      ...folder,
      ...profile,
      ...userService,
      ...documentRouter,
      ...mailTemplate,
      ...pageSetting,
    ]
  },
]

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const isAuthenticated = store.getters.isAuthenticated || !!localStorage.getItem('accessToken')
  const isFirstLogin = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).is_first_login : null;
  if (requiresAuth && !isAuthenticated) {
    next('login')
  }else if(to.name !== 'ChangePassword' && isFirstLogin) {
    next({ name: 'ChangePassword'})
  } else if (to.path === '/login' && isAuthenticated || (to.path === '/change-password' && !isFirstLogin)) {
    next('search')
  } else if (to.meta.requiresRoles) {
    // check USER PERMISSION HERE:
    const userRole = store.getters.authUser ? store.getters.authUser.role_id : (JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).role_id : null)
    if (!to.meta.requiresRoles.includes(userRole)) {
      Vue.$toast.error(FORBIDDEN)
      // const error = Error(FORBIDDEN);
      // store.dispatch(ACTION_SET_ERROR, error)
      next('403')
    }
  }
  const isItABackButton = window.popStateDetected
  window.popStateDetected = false
  if (isItABackButton) {
    store.dispatch(ACTION_CLEAR_SEARCH_PARAMS)
  }
  document.title = to.meta.title ? to.meta.title : i18n.t('app.title');
  next()
})

export default router
