const dateTimeFormat = 'yyyy-MM-dd hh:mm';
const yearMonthFormat = 'yyyy-MM';
const monthDayYearFormat = 'YYYY-MM-DD';
const attributeInputTypes = {
    1: 'text',
    2: 'select',
    3: 'multiselect',
    4: 'checkbox',
    5: 'radio',
    6: 'date',
    7: 'textbox',
    8: 'daterange',
}

const documentTypeMapper = [
    {
        code: '0001',
        name: '訪問介護',
        case: 'service_user'
    },
    {
        code: '0002',
        name: '訪問看護',
        case: 'service_user'
    },
    {
        code: '0003',
        name: '締結済契約',
        case: 'partner_name'
    },
    {
        code: '0004',
        name: '指定通知書',
        case: ''
    },
    {
        code: '0005',
        name: '各種ひな形',
        case: ''
    },
    {
        code: '0006',
        name: '社内規程',
        case: ''
    },
    {
        code: '0007',
        name: 'その他',
        case: ''
    },
]

const fileTypes = {
    1: 'word',
    2: 'pdf',
    3: 'image',
}

const emailSupport = 'info@inforle.com'
// eslint-disable-next-line no-control-regex
const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
const emailRegexCheck = /^([a-zA-Z0-9_.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export {
    dateTimeFormat,
    yearMonthFormat,
    monthDayYearFormat,
    attributeInputTypes,
    documentTypeMapper,
    fileTypes,
    emailSupport,
    emailRegex,
    emailRegexCheck
}
