<template>
  <div class="modal modal-fx-fadeInScale">
    <div class="modal-background"></div>
    <div class="modal-content"
         :style="{width: width}">
      <div class="modal-box box has-padding-lg br--10">
        <div class="modal-card-head modal-card-head-sreen-user">
          <p class="modal-card-title input-title color__blue_main text-center fw__bold"></p>
          <button class="delete" aria-label="close" @click="cancelClick"></button>
        </div>
        <div v-if="customIcon"
             class="box-icon is-custom"
             v-html="customIcon">
        </div>
        <div>
          <div class="icon-delete">
            <img src="../assets/svgs/delete_popup.svg" alt="" width="100">
          </div>
          <h3 v-if="title" class="box-title has-margin-bottom-lg color__blue_main text-title fw__bold">{{ title }} </h3>
          <div class="has-text-centered box-sub-title has-margin-left-lg has-margin-right-lg content content-sreen-user" v-html="content"/>
          <div class="modal-card-foot is-justify-content-center background__white" style="border: none">
            <button @click="okClick"
                    class="button close-modal btn-save m__right--10 fs-16" v-if="okText">
              {{ okText }}
            </button>
            <button @click="cancelClick"
                    class="button btn-back background__grey close-modal m__left--10 fs-16" v-if="cancelText">
              {{ cancelText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'popup',
    props: {
      type: {
        type: String,
        default: 'info',
      },
      size: {
        type: String,
        default: 'sm',
      },
      title: {
        type: String,
        default: '',
      },
      iconClass: {
        type: String,
        default: 'info-circle',
      },
      customIcon: {
        type: String,
        default: '',
      },
      content: {
        type: String,
        default: '',
      },
      width: {
        type: String,
        default: '',
      },
      okText: {
        type: String,
        default: 'OK',
      },
      cancelText: {
        type: String,
        default: 'Cancel',
      },
      onOk: {
        type: Function,
        default: () => {
        },
      },
      onCancel: {
        type: Function,
        default: () => {
        },
      },
      clickOut: {
        type: Boolean,
        default: true
      },
      onClickOut: {
        type: Function,
        default: () => {
        },
      },
      checkScreenUser: {
        type: Boolean,
        default: false
      },
    },
    data: () => ({
      timeShow: null,
    }),
    mounted() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$el.classList.add('is-active');
          this.freeze();

          if (this.clickOut) {
            document.body.addEventListener('click', this.bodyClickEv)
            document.body.addEventListener('keyup', this.bodyKeyEv)
          }
          this.$refs.modalConfirmButton.focus()
        }, 60);
      });
    },
    methods: {
      clickOutDialog() {
        this.closeDialog()
      },
      cancelClick() {
        this.onCancel(this);
        this.closeDialog()
      },
      outClick() {
        this.onClickOut(this)
      },
      closeDialog() {
        this.$el.classList.remove('is-active');
        this.$nextTick(() => {
          setTimeout(() => {
            this.$destroy();
            this.unFreeze();
            const parent = this.$el.parentNode;
            if (parent)
              parent.removeChild(this.$el);
          }, 350);
        });
      },
      okClick() {
        this.onOk(this);
      },
      freeze() {
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
      },
      unFreeze() {
        document.getElementsByTagName('html')[0].style.overflow = '';
        document.getElementsByTagName('body')[0].style.overflowY = '';
      },
      bodyClickEv($ev) {
        const dialog = this.$el.querySelector('.modal-box')
        if (dialog && !dialog.contains($ev.target)) {
          this.clickOutDialog()
          this.outClick()
        }
      },
      bodyKeyEv($ev) {
        if ($ev.key === 'Escape') {
          this.clickOutDialog()
          this.outClick()
        }
      },
      removeBodyEvent() {
        document.body.removeEventListener('click', this.bodyClickEv)
        document.body.removeEventListener('keyup', this.bodyKeyEv)
      }
    },
    beforeDestroy() {
      this.removeBodyEvent()
    }
  };
</script>

<style lang="scss" scoped>
  .btn-save {
    width: 140px !important;
    height: 35px !important;
    border-radius: 18px !important;
    background-color: $blue_main !important;
    border: none !important;
    font-size: 16px !important;
    display: block;
    &:focus {
      border: none !important;
      box-shadow: none;
    }
  }
  .btn-back {
    border-radius: 18px !important;
    height: 35px !important;
    width: 140px !important;
    border:  none !important;
    font-size: 16px !important;
    background-color: #E4E4E4 !important;
    color: #979797 !important;
    display: block;
  }
  .box {
    .box-title {
      text-align: center;
      color: #373a3c;
      margin: 30px;
    }

    .has-icon-circle {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      margin: 0 auto;
      border-width: 2px;
      border-style: solid;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      font-size: 25px;
    }

    .modal-footer-button {
      margin: 20px;
    }
    .content{
      white-space: normal;
      word-wrap: break-word;
    }
  }
  @media screen and (min-width: 769px) {
    .modal-content, .modal-card {
      width: 700px;
      height: 450px;
    }
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
    .modal {
      .modal-content {
        .box-title {
          font-size: 18px !important;
          font-weight: bold !important;
          margin: 10px !important
        }

        .box-sub-title {
          font-size: 12px !important;
        }

        width: 90%;
        margin: auto;

        .modal-footer-button {
          margin-top: 20px;
          margin-bottom: 10px
        }
      }
    }
  }

  @media only screen and (min-device-width: 800px) and (max-device-width: 1280px) {
    .modal {
      .modal-content {
        .box-title {
          font-size: 18px !important;
          font-weight: bold !important;
          margin: 10px !important
        }

        .box-sub-title {
          font-size: 12px !important;
        }

        width: 90%;
        margin: auto;

        .modal-footer-button {
          margin-top: 20px;
          margin-bottom: 10px
        }
      }
    }
  }
  .text-title {
    font-size: 20px;
    margin-bottom: 0px !important;
  }
  .content-sreen-user {
    font-size: 14px;
  }
  .icon-delete {
    margin-top: 70px;
    width: 100%;
    text-align: center;
  }
  .modal-card-head-sreen-user {
    background-color: white;
    border: none;
    padding: 0;
  }
</style>
