import ApiService from '@/services/ApiService';

const MailTemplateService = {
  list(page, limit) {
    return ApiService.get(`mail-templates?page=${page}&limit=${limit}`)
  },

  create(data) {
    return ApiService.post('mail-templates', data)
  },

  update(id, data) {
    return ApiService.update(`mail-templates/${id}`, data)
  },

  delete(id) {
    return ApiService.delete(`mail-templates/${id}`)
  },

  detail(id) {
    return ApiService.get(`mail-templates/${id}`)
  },

    deleteAll(data) {
        return ApiService.post(`mail-templates/delete`, data)
    },
}

export default MailTemplateService