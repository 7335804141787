import i18n from "@/lang/i18n";
export default [
  {
    path: '/b-to-b/:id',
    name: 'BtoB',
    component: () => import('@/views/documents/BtoB'),
    meta: {
      requiresAuth: true,
      title: i18n.t('router.document_detail'),
    }
  },
  {
    path: '/b-to-c/:id',
    name: 'BtoC',
    component: () => import('@/views/documents/BtoC'),
    meta: {
      requiresAuth: true,
      title: i18n.t('router.document_detail'),
    }
  },
  {
    path: '/document/create',
    name: 'CreateDocument',
    component: () => import('@/views/documents/DocumentCreate'),
    meta: {
      requiresAuth: true,
      title: i18n.t('router.document_create'),
    }
  },
  {
    path: '/document/update/:id',
    name: 'UpdateDocument',
    component: () => import('@/views/documents/DocumentUpdate'),
    meta: {
      requiresAuth: true,
      title: i18n.t('router.document_update'),
    }
  },
  {
    path: '/document/:id/setting-alert-mail',
    name: 'SettingAlertMail',
    component: () => import('@/views/documents/SettingAlertMail'),
    meta: {
      requiresAuth: true,
      title: i18n.t('router.document_alert'),
    }
  },
]
