import i18n from "@/lang/i18n";
export default [
  {
    path: 'alert-general-setting',
    name: 'PageSetting',
    component: () => import('@/views/page-setting/PageSetting'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2],
      title: i18n.t('router.page_setting')
    },
  },
]
