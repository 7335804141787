import i18n from "@/lang/i18n";
export default [
  {
    path: 'search',
    name: 'SearchDocuments',
    component: () => import('@/views/search/SearchDocuments'),
    meta: {
      requiresAuth: true,
      title: i18n.t('router.search_document')
    },
  },
]
