import i18n from "@/lang/i18n";

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login'),
    meta: {
      title: i18n.t('router.login'),
    }
  },
  {
    path: '/logout',
    meta: {
      requiresAuth: true
    },
    name: 'logout',
    component: () => import('@/views/auth/Logout'),
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('@/views/auth/ForgotPassword'),
    meta: {
      title: i18n.t('router.forgot_password'),
    }
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    component: () => import('@/views/auth/ResetPassword'),
    meta: {
      title: i18n.t('router.reset_password'),
    }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/views/auth/ChangePassword'),
    meta: {
      requiresAuth: true,
      title: i18n.t('router.change_password'),
    },
  },
]
