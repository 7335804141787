import i18n from "@/lang/i18n";
export default [
    {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/profile/Profile'),
        meta: {
            requiresAuth: true,
            // requiresRoles: [1, 2]
            title: i18n.t('router.profile')
        },
    },
]
