export const ACTION_SET_ERROR = 'actionSetError'
export const ACTION_CLEAR_ERROR = 'actionClearError'
export const ACTION_SET_LOADING = 'actionSetLoading'
export const ACTION_FINISH_LOADING = 'actionFinishLoading'
export const ACTION_CLEAR_LOADING = 'actionClearLoading'
export const ACTION_SET_MESS= 'actionSetMessage'
export const ACTION_CLEAR_MESS = 'actionClearMessage'
export const ACTION_SET_PAGE_TITLE = 'actionSetPageTitle'
export const ACTION_SET_ACTIVE_SIDEBAR = 'actionActiveSidebar'
export const ACTION_CLEAR_SEARCH_PARAMS = 'actionClearSearchParams'
export const ACTION_SET_SEARCH_PARAMS = 'actionSearchParams'
export const ACTION_SET_SPECIAL_LOADING = 'actionSpecialLoading'
export const ACTION_SET_DATA_LOADED = 'actionDataLoaded'
