import ApiService from '@/services/ApiService';

const PageSettingService = {
  getSettingAlert() {
    return ApiService.get('general-setting')
  },
  settingAlert(data) {
    return ApiService.update('general-setting', data)
  },
}

export default PageSettingService