import i18n from "@/lang/i18n";
export default [
  {
    path: 'folders',
    name: 'Folders',
    component: () => import('@/views/folders/FolderList'),
    meta: {
      requiresAuth: true,
      title: i18n.t('router.folder')
    },
  },
]
