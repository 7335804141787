const NO_INTERNET = "NO INTERNET RETRY"
const UNAUTHENTICATED = "セッションタイムアウトが発生しました。再度ログインしてください"
const FORBIDDEN = "アクセス権がありません"
const LOGIN_FAILED = "IDまたはパスワードが違います"
const SERVER_ERROR = "サーバーエラーもう一度やり直してください"
const INVALID_INPUT = "ファイルのデータが不正です"
const NOT_FOUND = "404 not found"


export {
    NO_INTERNET,
    UNAUTHENTICATED,
    FORBIDDEN,
    LOGIN_FAILED,
    SERVER_ERROR,
    INVALID_INPUT,
    NOT_FOUND
}