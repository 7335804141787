import Loading from "./Loading"
import multiselect from "./multiselect"
import multiselect_permission from "./multiselect_permission"
import Pagination from "./Pagination"
import DropdownSearch from "./dropdown-search"

export default (Vue) => {
  Vue.component('loading', Loading)
  Vue.component('multiselect', multiselect)
  Vue.component('multiselect_permission', multiselect_permission)
  Vue.component('pagination', Pagination)
  Vue.component('dropdown-search', DropdownSearch)
}