import ApiService from '@/services/ApiService';

const UserUsageService = {
    list(page, limit) {
        return ApiService.get(`service-users?page=${page}&limit=${limit}`)
    },

    create(data) {
        return ApiService.post('service-users', data)
    },

    update(id, data) {
        return ApiService.update(`service-users/${id}`, data)
    },

    delete(id) {
        return ApiService.delete(`service-users/${id}`)
    },

    detail(id) {
        return ApiService.get(`service-users/${id}`)
    },
    deleteAll(data) {
        return ApiService.post(`service-users/delete`, data)
    },
}

export default UserUsageService