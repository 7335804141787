<template>
  <div>
    <!--    {{ listDataClone}}-->
    <div class="dropdown w--100"
         :id="'dropdown-search' + idPrefix"
         v-click-out="()=>closeDropdown('dropdown-search' + idPrefix)">
      <div class="dropdown-trigger w--100 field is-grouped m__bottom--0" :class="{'cursor-not-allowed': disabled}">
        <span class="selected-prefix p__left--10 fs-14 color__blue_main" v-if="tempSelected && displayTemp"
              @click.stop="toggleDropdown($event, 'dropdown-search' + idPrefix)"
              :class="{'color-selected' : disabled}">{{ tempSelected }}</span>
        <input class="input w--100"
               v-model="keyword"
               :disabled="disabled"
               ref="keyword"
               v-click-out="revertSelectedData"
               :placeholder="tempSelected ? '' : placeholder"
               @keyup="searchData()"
               :class="{'is-error': isInvalid}"
               @click="toggleDropdown($event, 'dropdown-search' + idPrefix)">
        <span class="icon is-small" @click.stop="toggleDropdown($event, 'dropdown-search' + idPrefix)"
              :class="{'d-none' : disabled }">
          <img width="15px" src="@/assets/svgs/ic_drop_2.svg"/>
        </span>
      </div>
      <div class="dropdown-menu w--100" role="menu">
        <div class="dropdown-content">
          <template v-if="listDataClone && listDataClone.length">
            <a @click="selectData(e)"
               v-for="(e, i) in listDataClone"
               :key="'drop-search-item-' + i"
               :class="{'is-active': e.id === selectedData.id}"
               class="dropdown-item fs-14 color__blue_main">
              {{ e.specialDisplay || e[display] }}
            </a>
          </template>
          <div class="dropdown-item color__blue_main" v-else-if="!specialLoading">
            {{ $t('target_not_found') }}
          </div>
          <div class="has-text-centered" v-if="(paginate && list.length < paginate.total && isLazyLoad) || specialLoading">
            <img src="@/assets/svgs/ic_loading.svg" class="icon-loading">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import debounce from "lodash/debounce";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import {mapGetters} from "vuex";

export default {
  name: "dropdown-search",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    display: {
      type: String,
      default: 'name'
    },
    selected: {
      type: [Number, String],
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    defaultKeyword: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    stringCompare: {
      type: String,
      default: 'id'
    },
    idPrefix: {
      type: String,
      default: ''
    },
    searchApi: {
      type: Boolean,
      default: false
    },
    lazyLoad: {
      type: Boolean,
      default: false
    },
    paginate: {
      type: Object,
      default: () => {
      }
    },
    isLazyLoad: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      keyword: '',
      oldKeyword: '',
      listDataClone: [],
      selectedData: {},
      tempSelected: '',
      tempSelectedClone: '',
      searchTimeout: null,
      lazyLoadTimeOut: null,
      displayTemp: true,
      isFirstLoad: true,
    }
  },
  computed: {
    ...mapGetters(['isLoading', 'specialLoading']),
  },
  watch: {
    list() {
      this.getData()
      this.loadSelected()
    },
    selected() {
      this.loadSelected()
    },
    defaultKeyword(val) {
      if (val) {
        this.keyword = val
        this.loadSelected()
      }
    },
  },
  methods: {
    loadSelected() {
      if (this.$el.querySelector(`#dropdown-search${this.idPrefix}`) && !this.$el.querySelector(`#dropdown-search${this.idPrefix}`).classList.contains('is-active')) {
        this.keyword = ''
      }
      if (this.selected) {
        let index = findIndex(this.list, e => e[this.stringCompare] === this.selected)
        if (index >= 0) {
          this.selectedData = this.list[index]
          this.tempSelected = {...this.list[index]}.specialDisplay || {...this.list[index]}[this.display]
          this.tempSelectedClone = {...this.list[index]}.specialDisplay || {...this.list[index]}[this.display]
          // this.keyword = this.list[index][this.display]
          // this.keyword = ''
        } else {
          // this.keyword = this.defaultKeyword || ''
          this.tempSelected = ''
          this.tempSelectedClone = ''
        }
      } else {
        this.selectedData = {}
        // this.keyword = this.defaultKeyword || ''
        this.tempSelected = ''
        this.tempSelectedClone = ''
      }
    },
    revertSelectedData() {
      this.$refs.keyword.blur()
      this.displayTemp = true
      if (!this.isLoading) {
        if (this.selectedData && this.selectedData[this.stringCompare]) {
          let index = findIndex(this.list, e => e[this.stringCompare] === this.selectedData[this.stringCompare])
          if (index >= 0) {
            this.selectedData = {...this.list[index]}
            this.tempSelected = {...this.list[index]}.specialDisplay || {...this.list[index]}[this.display]
            this.tempSelectedClone = {...this.list[index]}.specialDisplay || {...this.list[index]}[this.display]
            this.$nextTick(() => {
              this.selectData(this.selectedData)
            })
          }
        } else {
          this.$emit('change', {id: null, name: ''})
          this.listDataClone = [...this.list]
        }
      }
      this.keyword = ''
    },
    getData() {
      if (!this.isFirstLoad && this.keyword && this.keyword.length > 0) {
        this.listDataClone = [...this.list]
        this.$nextTick(() => {
          this.listDataClone = filter(this.listDataClone, (e) => {
            return (e.name && e.name.toLowerCase().indexOf(this.keyword && this.keyword.toLowerCase()) > -1)
                || (e.code && e.code.toLowerCase().indexOf(this.keyword && this.keyword.toLowerCase()) > -1)
          })
          this.listDataClone = [...this.listDataClone]
        })
      } else {
        this.listDataClone = [...this.list]
      }
    },
    searchData() {
      this.isFirstLoad = false
      document.getElementById('dropdown-search' + this.idPrefix) && document.getElementById('dropdown-search' + this.idPrefix).classList.add('is-active')
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        if (this.searchApi) {
          this.$emit('search', this.keyword)
        } else {
          this.getData()
        }
      }, 500)
    },
    closeDropdown(id) {
      this.$nextTick(() => {
        document.getElementById(id) && document.getElementById(id).classList.remove('is-active')
      })
    },
    closeAllDropdowns(list) {
      list.map((e) => {
        this.closeDropdown(e)
      })
    },
    toggleDropdown(e, id) {
      this.displayTemp = false
      this.isFirstLoad = true
      this.listDataClone = []
      this.closeAllDropdowns(['dropdown-searchbranches', 'dropdown-searchdivisions', 'dropdown-searchoffices'])
      if (!this.disabled) {
        this.$nextTick(() => {
          if (this.searchApi && !this.specialLoading && !this.$el.querySelector(`#${id}`).classList.contains('is-active')) {
            this.$emit('search', '')
          } else {
            this.getData()
          }
          this.$el.querySelector(`#${id}`) && this.$el.querySelector(`#${id}`).classList.add('is-active')
          if (!this.keyword) this.keyword = this.tempSelectedClone
          this.tempSelected = ''
          this.$refs.keyword.focus()
          this.$nextTick(() => {
            e.target.value && e.target.setSelectionRange(0, this.keyword.length)
          })
        })
      }
    },
    selectData(e) {
      this.selectedData = {...e}
      // this.keyword = e[this.display]
      this.$emit('change', this.selectedData)
      // this.searchData()
      this.closeDropdown('dropdown-search' + this.idPrefix)
    },
    lazyLoadData() {
      if (this.paginate && this.list.length < this.paginate.total) {
        let content = this.$el.querySelector('.dropdown-content')
        if (content.scrollHeight - content.clientHeight - 5 >= content.scrollTop && !this.isLazyLoad) {
          clearTimeout(this.lazyLoadTimeOut)
          this.lazyLoadTimeOut = setTimeout(() => {
            this.$emit('load-more', {
              keyword: this.isFirstLoad ? '' : this.keyword,
              search: true,
              page: this.paginate.currentPage + 1,
              per_page: this.paginate.perPage
            })
          }, 200)
        }
      }
    }
  },
  mounted() {
    this.getData()
    this.selectedData.id = this.selected
    // this.keyword = this.defaultKeyword || ''
    let content = this.$el.querySelector('.dropdown-content')
    if (this.lazyLoad) {
      content.addEventListener('scroll', this.lazyLoadData)
    }
  },
  destroyed() {
    let content = this.$el.querySelector('.dropdown-content')
    if (this.lazyLoad) {
      content.removeEventListener('scroll', this.lazyLoadData)
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-content {
  max-height: 300px;
  overflow-y: scroll;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}
#dropdown-search {
  .dropdown-trigger, input {
    &:hover, &:focus {
      cursor: pointer;
      border-color: $blue_main;
      box-shadow: none !important;
    }

    &.is-error {
      &:hover, &:focus {
        // box-shadow: 0 0 0 0.125em rgba(255, 102, 115, 0.35) !important;
      }
    }
  }
}
input {
  border-radius: 5px !important;
  &.input {
    height: 40px;
    font-size: 12px;
    color: $blue_button;
    font-weight: bold;
  }
  &:focus {
    border-color: $blue_button !important;
    box-shadow: none !important;
  }
  &:hover {
    border-color: $blue_button !important;
    box-shadow: none !important;
  }
}
.selected-prefix {
  position: absolute;
  left: 11px;
  top: 11px;
  z-index: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
}

.color-selected {
  color: #7a7a7a;
}

.icon.is-small {
  top: 30%;
}

.icon-loading {
  width: 2rem !important;
  height: 2rem !important;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  .selected-prefix {
    width: 250px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
  }
}
</style>
