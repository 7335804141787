import i18n from "@/lang/i18n";
export default [
  {
    path: 'import',
    name: 'ImportMailAddress',
    component: () => import('@/views/import/ImportMailAddress'),
    meta: {
      requiresAuth: true,
      requiresRoles: [1, 2],
      title: i18n.t('router.import_mail')
    },
  },
]
