import Vue from 'vue';
import App from '@/App.vue';
import Toasted from 'vue-toasted';
import * as filters from '@/filters';
import Vuelidate from 'vuelidate';
import router from '@/routers';
import store from '@/stores';
import { ApiService } from '@/services';
import i18n from "@/lang/i18n";
import Bulma from "bulma";
import BulmaTooltip from "bulma-tooltip";
import BulmaSwitch from "bulma-switch";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Components from '@/components/common'
import Directive from '@/directives'
import Prototypes from '@/prototypes'
import Mixins from '@/mixins'

Vue.config.productionTip = false

ApiService.init()
ApiService.setHeader()

Vue.use(VueToast, {
  position: 'top-right',
  singleton: true,
});

Vue.use(Vuelidate);
Vue.use(Bulma);
Vue.use(BulmaTooltip);
Vue.use(BulmaSwitch);
Vue.use(Toasted, {
  position: 'top-right',
  singleton: true,
});

Components(Vue);
Directive(Vue);
Mixins(Vue);
Vue.use(Prototypes);

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
