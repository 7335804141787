<template>
  <div id="app">
    <Loading :loading="isLoading" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "./components/common/Loading";
import {ACTION_SAVE_AUTH_USER} from "@/stores/auth/actions";

export default {
  name: "App",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isLoading", "error", "message", 'authUser']),
  },
  data() {
    return {};
  },
  watch: {
    error(error) {
      if (error !== null && error.message !== "") {
        this.$toasted.show(error.message)
      }
    },
    message(value) {
      if (value) {
        this.$toasted.show(value)
      }
    },
    isAuthenticated(value) {
      if (!value) {
        this.$router.push({ name: "login" }, () => {})
      }
    },
  },
  created() {
    if(!this.authUser ||!this.authUser.id) {
      this.$store.dispatch(ACTION_SAVE_AUTH_USER)
    }
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "assets/css/main.css";
@import "assets/scss/commons.scss";
@import "assets/scss/home-page.scss";
</style>
